import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #fffdd0;
`;

const FormContainer = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  height: 400px;
  width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  /* Apply the slide-in animation */
  animation: ${slideIn} 0.8s ease forwards;
  opacity: 0;
`;

const FormTitle = styled.h2`
  margin-bottom: 24px;
  font-size: 28px;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const FormButton = styled.button`
  width: 100px;
  padding: 12px;
  font-size: 16px;
  background-color: #ffde59;

  color: black;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #fee89e;
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;
const Formbutton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
function Registerpage() {
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Container id="register">
      <FormContainer>
        <FormTitle>Get early access</FormTitle>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormInput
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="country">Country</FormLabel>
            <FormSelect
              id="country"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">Select your country</option>
              <option value="USA">United States</option>
              <option value="Canada">Canada</option>
              <option value="UK">United Kingdom</option>
              <option value="Australia">Australia</option>
              <option value="India">India</option>
            </FormSelect>
          </FormGroup>
          <Formbutton>
            <FormButton type="submit">Submit</FormButton>
          </Formbutton>
        </form>
      </FormContainer>
    </Container>
  );
}

export default Registerpage;
