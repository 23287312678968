import React from "react";
import styled from "styled-components";
import logoimage from "../assests/images/Logo.png";

const Container = styled.div`
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "BryndanWriteBook", sans-serif;
  font-size: 40px;
  padding: 10px 20px;
`;
const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  z-index: 2;
`;
const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin-right: 30px;
`;

const Navlink = styled.li`
  margin-left: 20px;
  padding-right: 10px;
`;

const NavAnchor = styled.a`
  text-decoration: none;
  color: #60555d;
  font-size: 30px;
`;
const Image = styled.img`
  max-width: 90px;
  max-height: 80px;
`;

function Navbar() {
  return (
    <Container>
      <LogoAndTitle>
        <Image src={logoimage} alt="logo"></Image>
        <Title>chalkolate</Title>
      </LogoAndTitle>
      <NavLinks>
        <Navlink>
          <NavAnchor href="#register">Register</NavAnchor>
        </Navlink>
        <Navlink>
          <NavAnchor href="#aboutus">About-us</NavAnchor>
        </Navlink>
      </NavLinks>
    </Container>
  );
}

export default Navbar;
