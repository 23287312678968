import React from "react";
import styled, { keyframes } from "styled-components";
import myimage from "../assests/images/planting.png";

const moveUpDown = keyframes`
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 90%;
  padding: 20px;
  display: grid;
  align-items: center;
  justify-content: center;

  grid-template-columns: 3fr 1fr;

  gap: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const Title = styled.div`
  font-family: "BryndanWriteBook", sans-serif;
  font-size: 54px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  z-index: 2;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 80%;
  object-fit: cover;
  animation: ${moveUpDown} 3s ease-in-out infinite;
`;

const Feat = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  justify-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  font-family: "BryndanWriteBook", sans-serif;
  font-size: 30px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 40px;
  z-index: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  width: 100%;
  &:hover {
    transform: translateY(-10px);
  }
`;

function Home() {
  return (
    <Container>
      <LeftContainer>
        <Title>making home school easy !</Title>
        <Feat>
          <FeatureCard>
            Real-life study groups and a dedicated parents' network.
          </FeatureCard>
          <FeatureCard>Learning by doing: a hands-on curriculum</FeatureCard>
          <FeatureCard>
            Dedicated support to help parents become even better teachers
          </FeatureCard>
          <FeatureCard>Marketplace to buy & sell books and toys</FeatureCard>
        </Feat>
      </LeftContainer>
      <RightContainer>
        <Image src={myimage} alt="images" />
      </RightContainer>
    </Container>
  );
}

export default Home;
