import styled, { createGlobalStyle } from "styled-components";
import React from "react";
import Navbar from "./Components/Navbar";
import Home from "./pages/Homepage";
import Registerpage from "./pages/Registerpage";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "BryndanWriteBook";
    src: url("/fonts/BryndanWriteBook-nGPM.ttf") format("truetype");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  
  }
`;

const AppContainer = styled.div`
  background-color: #ffde59;
  color: #60555d;
  width: 100vw;
  height: 100vh;
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Navbar />
        <Home />
        <Registerpage />
      </AppContainer>
    </>
  );
}

export default App;
